import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Startseite | The Pitch Tavern!
			</title>
			<meta name={"description"} content={"Von epischen Zusammenstößen bis hin zu herzlichen Kameradschaften, The Pitch Tavern ist der Ort, an dem die Welt des Sports auf Komfort trifft!"} />
			<meta property={"og:title"} content={"Startseite | The Pitch Tavern!"} />
			<meta property={"og:description"} content={"Von epischen Zusammenstößen bis hin zu herzlichen Kameradschaften, The Pitch Tavern ist der Ort, an dem die Welt des Sports auf Komfort trifft!"} />
			<meta property={"og:image"} content={"https://cyroqau.com/img/counter_1.jpg"} />
			<link rel={"shortcut icon"} href={"https://cyroqau.com/img/1651826.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://cyroqau.com/img/1651826.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://cyroqau.com/img/1651826.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://cyroqau.com/img/1651826.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://cyroqau.com/img/1651826.png"} />
			<meta name={"msapplication-TileImage"} content={"https://cyroqau.com/img/1651826.png"} />
			<meta name={"msapplication-TileColor"} content={"https://cyroqau.com/img/1651826.png"} />
		</Helmet>
		<Components.Header />
		<Section background="--color-darkL2" padding="60px 0" sm-padding="40px 0">
			<Box
				margin="-16px -16px -16px -16px"
				padding="0px 0px 0px 0px"
				display="flex"
				width=" "
				flex-wrap="wrap"
			>
				<Box padding="16px 16px 16px 16px" display="flex" width="50%" lg-width="100%">
					<Box
						padding="98px 64px 98px 64px"
						display="flex"
						flex-direction="column"
						background="--color-light"
						color="--dark"
					>
						<Text
							as="p"
							margin="12px 0"
							font="--base"
							color="--grey"
							letter-spacing="1px"
							text-transform="uppercase"
						>
							Von epischen Zusammenstößen bis hin zu herzlichen Kameradschaften
						</Text>
						<Text as="h1" margin="12px 0" font="--headline1" md-font="--headline3">
							Willkommen in der Pitch Tavern
						</Text>
						<Text as="p" margin="12px 0" font="--lead">
							The Pitch Tavern ist nicht nur eine Sportbar, sondern vielmehr ein gemütlicher Zufluchtsort, an dem sich Liebhaber, Enthusiasten und Angehörige treffen. Das harmonische Echo legendärer Kämpfe, jubelnder Rufe und Herzklopfen voller Vorfreude lässt diesen Ort wie einen Wandteppich aus Emotionen und Erinnerungen erscheinen. Mit seinem eleganten Industriedesign, dem gedämpften Licht und der modernen Sportausrüstung spiegelt es das dynamische und sich entwickelnde Gesicht der Sportwelt wider. Es ist mehr als nur ein Veranstaltungsort, es ist das Epizentrum von Erzählungen, Kameradschaften und sich entfaltenden Dramen.
						</Text>
						<Link
							href="/contacts"
							padding="22px 24px 12px 24px"
							color="--light"
							text-decoration-line="initial"
							font="--lead"
							border-radius="8px"
							margin="0px 16px 0px 0px"
							transition="background-color 0.2s ease-in-out 0s"
							sm-margin="0px 0px 16px 0px"
							sm-text-align="center"
							background="--color-red"
							hover-transition="background-color 0.2s ease-in-out 0s"
							hover-background="--color-primary"
							display="inline-block"
							flex="0 0 auto"
							max-width="250px"
							text-align="center"
							align-self="center"
						>
							Reservierung
						</Link>
					</Box>
				</Box>
				<Box display="flex" width="50%" flex-wrap="wrap" lg-width="100%">
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="100%"
						lg-width="33.3333%"
						md-width="100%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 624px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://cyroqau.com/img/counter_1.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://cyroqau.com/img/counter_2.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://cyroqau.com/img/background_1.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section text-align="center" padding="80px 0" sm-padding="40px 0">
			<Text as="h2" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
				Unser unverwechselbarer Reiz:
			</Text>
			<Text as="p" font="--lead" margin="20px 0 0 0">
				Vereint durch die Liebe zum Spiel: Unsere Arrangements betonen die Einheit. Mit halbkreisförmigen Ständen kommen Fans unterschiedlicher Zugehörigkeit zusammen, die sich dem Spielgeschehen hingeben und Konflikte durch nette Gespräche ersetzen.
				<br />
				<br />
				Das Loft Retreat: Eine Etage höher gelegen, bietet diese ruhige Nische den Komfort von Ledersofas, einen modernen Kamin und den sanften Schein von Hängelampen, die den Ton für tiefgehende Gespräche oder entspanntes Faulenzen angeben.
				<br />
				<br />
				Flüsternde Mixologen: Unsere Barkeeper, die sich sowohl mit Sporttrivialitäten als auch mit der Kunst der Getränkezubereitung auskennen, sind Ihre Ansprechpartner für interessante Gespräche. Diskutieren Sie über ein legendäres Spiel oder sinnieren Sie einfach über das Leben - sie sind immer bereit, mitzumachen.
			</Text>
			<Box display="flex" margin="40px 0 20px 0" justify-content="space-around" sm-flex-direction="column">
				<Box padding="10px">
					<Image src="https://cyroqau.com/img/inside_3.jpg" width="320px" max-width="100%" />
				</Box>
				<Box padding="10px">
					<Image src="https://cyroqau.com/img/inside_4.jpg" width="320px" max-width="100%" />
				</Box>
				<Box padding="10px">
					<Image src="https://cyroqau.com/img/inside_5.jpg" width="320px" max-width="100%" />
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Box display="grid" grid-template-columns="repeat(3, 1fr)" grid-gap="32px 4%" md-grid-template-columns="1fr">
				<Box align-items="center" justify-content="flex-start" flex-direction="column" display="flex">
					<Text margin="0px 0px 11px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Himmelssicht-Deck:
					</Text>
					<Text
						margin="0px 0px 12px 0px"
						color="--greyD3"
						font="--base"
						text-align="center"
						flex="1 0 auto"
					>
						Genießen Sie den Nervenkitzel in der Umarmung des Abendhimmels. Unsere mit stimmungsvoller Beleuchtung ausgestattete Terrasse bietet eine erfrischende Mischung aus Natur und Sport, die den Nervenkitzel einer Arena mit den Annehmlichkeiten eines Rückzugsortes verbindet.
					</Text>
				</Box>
				<Box align-items="center" justify-content="flex-start" flex-direction="column" display="flex">
					<Text margin="0px 0px 11px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Global Playbook Bites:
					</Text>
					<Text
						margin="0px 0px 12px 0px"
						color="--greyD3"
						font="--base"
						text-align="center"
						flex="1 0 auto"
					>
						Unsere Gerichte sind eine gastronomische Reise durch weltberühmte Arenen. Von knusprigen Frühlingsrollen bis zu Gourmet-Pizzen ist jede Portion eine Ode an den globalen Geist des Sports.
					</Text>
				</Box>
				<Box align-items="center" justify-content="flex-start" flex-direction="column" display="flex">
					<Text margin="0px 0px 11px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Fesseln und Anfeuern:{" "}
					</Text>
					<Text
						margin="0px 0px 12px 0px"
						color="--greyD3"
						font="--base"
						text-align="center"
						flex="1 0 auto"
					>
						Nur auf der Durchreise oder längerfristig hier? Unsere "Bonding Benches" sind der Knotenpunkt, an dem Sie Gleichgesinnte treffen und neue Geschichten in diesem pulsierenden Sportkosmos erzählen können.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 32px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://cyroqau.com/img/inside_6.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				justify-content="center"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Was sich in der Pitch Tavern zusammenbraut:
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					🍷 Tavern Toast Time: Jeden Freitag, 18:00 - 20:00 Uhr - Nippen Sie an kuratierten Getränken und tauschen Sie Anekdoten aus.
					<br />
					<br />
					🎉 Team Tee Fiesta: Ziehen Sie samstags das T-Shirt Ihrer Lieblingsmannschaft an und erhalten Sie eine spezielle Mixologenmischung auf Kosten des Hauses.
					<br />
					<br />
					🍕 Pizzazz Pizza Nights: Entdecken Sie jeden Dienstag unser exklusives Pizzasortiment, das den Geschmack von Sportstädten aus aller Welt widerspiegelt.
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-red"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Buchen Sie einen Tisch
				</Link>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});